import React from 'react'
import { HeroBlock } from '../components/HeroBlock'
import { ServiceCard } from '../components/ServiceCard'

export const Services = () => {
  const services = [
    {
      title: "Разработка комплекта чертежей",
      desc: "Разработаем полный комплект чертежей для  проекта,  который  будет  использоваться  как  руководство  для  строительства  и  отделки",
      icon: "develop.svg"
    },
    {
      title: "Разработка спецификаций и бюджета",
      desc: "Разработаем детальный бюджет  проекта  с  разбивкой  по  статьям  расходов,  который  будет  использоваться  для  планирования  финансов  и  контроля  за  расходами.",
      icon: "clipboard.svg"
    },
    {
      title: "Координация работ подрядчиков",
      desc: "Бесперебойное и эффективное  взаимодействие  между  разными  подрядчиками,  задействованными  в  проекте",
      icon: "3people.svg"
    },
    {
      title: "Cтроительные и отделочные работы",
      desc: "Совместно с нашими партнерами обеспечим выполнение всех  необходимых  строительных  и  отделочных  работ  в  соответствии  с  проектом",
      icon: "instruments.svg"
    },
    {
      title: "Полная комплектация проекта",
      desc: "Создание  списка  всех  необходимых  материалов  с  указанием  количества,  типа  и  производителя. Поиск  поставщиков и согласование необходимого",
      icon: "bag.svg"
    },
    {
      title: "Авторский надзор",
      desc: "Результатом Авторского надзора является качественное выполнение  всех  работ  в  соответствии  с  проектом  и  стандартами",
      icon: "author_check.svg"
    },
  ]
  return (
    <div className="page">
      <HeroBlock 
        title="Услуги" 
        thread="услуги" 
        desc="Мы снимаем с заказчиков головную боль управления всеми этапами проекта от разработки концпеции, строительства, осуществления закупок, администрирования до переезда."
        bg="/images/hero_services.jpg"/>
      <div className="site-container">
        <div className="services-container">
          {services.map((card, index) => (
            <ServiceCard icon={card.icon} key={index} title={card.title} desc={card.desc}/>
          ))}
          
        </div>
      </div>
    </div>
  )
}
