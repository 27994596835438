import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { Button } from './Button'
import emailjs from '@emailjs/browser';

export const ContactForm = () => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("7")
  const [mail, setMail] = useState("")
  const [service, setService] = useState("")
  const [request, setRequest] = useState("")
  const [isSending, setIsSending] = useState(false)

  let form = {
    name: name,
    phone: phone,
    mail: mail,
    service: service,
    request: request
  };

  function sendInfo() {
    if (name.trim() !== '' && phone.trim() !== '') {
      var templateParams = {
        message: `
        Имя: ${name.trim()}
        Телефон: ${phone.trim()}
        Почта: ${mail.trim() !== '' ? mail.trim() : "Не указано"}
        Услуга: ${service.trim() !== '' ? service.trim() : "Не указано"}
        Запрос: ${request.trim() !== '' ? request.trim() : "Не указано"}
        `,
        from_name: name.trim()
      };
      setIsSending(true)
      emailjs.init({
        publicKey: "B__dcMPI0doy5UYoC",
      });
      emailjs.send(process.env.REACT_APP_EMAIL_SERVICEID, 'template_brlrueo', templateParams).then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Ваш запрос успешно отправлен. Мы свяжемся с вами в ближайшее время')
          setIsSending(false)
          setName('')
          setPhone('')
          setMail('')
          setService('nothing')
          setRequest('')
        },
        (error) => {
          console.log('FAILED...', error);
          setIsSending(false)
        },
      );
    } else {
      alert("Вы не заполнили обязательные поля формы")
    }
  }


  return (
    <div className={isSending ? "consult-form loading" : "consult-form"}>
      <h2>Консультация</h2>
      <div className="group1">
        <span className={name.trim()=='' && "required"}>
          <input type="text" name="name" id="1" placeholder='Имя' value={name} onChange={e => setName(e.target.value)} />
        </span>
        <span className={phone.trim().length<2 && phone.trim()!=="" ? "required phone" : ""}>
          <PhoneInput
            country={'ru'}
            value={phone}
            onChange={setPhone}
            countryCodeEditable={false}
            placeholder='+7 (000) 000-00-00'
            specialLabel=""
          />
        </span>
      </div>
      <div className="group2">
        <input type="email" name="mail" id="2" placeholder='Адрес эл. почты' value={mail} onChange={e => setMail(e.target.value)} />
        <select name="service" id="3" value={service} onChange={e => setService(e.target.value)}>
          <option value="nothing" hidden>Выберите желаемый пакет услуг</option>
          <option value="Разработка комплекта чертежей">Разработка комплекта чертежей</option>
          <option value="Разработка спецификаций и бюджета">Разработка спецификаций и бюджета</option>
          <option value="Координация работ подрядчиков">Координация работ подрядчиков</option>
          <option value="Cтроительные и отделочные работы">Cтроительные и отделочные работы</option>
          <option value="Полная комплектация проекта">Полная комплектация проекта</option>
          <option value="Авторский надзор">Авторский надзор</option>
        </select>
      </div>
      <textarea name="request" id="4" placeholder='Напишите ваш запрос' value={request} onChange={e => setRequest(e.target.value)}></textarea>
      <span className="required hint">
        Обязательное заполнение
      </span>
      <Button isLoading={isSending.toString()} text="Отправить запрос" onClick={sendInfo} />
      <div className="consult-form_disclaimer">Отправляя запрос, вы даете согласие на обработку персональных данных.</div>
    </div>
  )
}
